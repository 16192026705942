<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="https://www.edshed.com" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <!-- <div class="center-block" style="width: fit-content; text-align: center">
                <img src="/img/logo.png" class="inline-block sublogo">
                <img src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo" v-if="$route.query.locale == 'en_US'">
                <img src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo" v-else>
              </div> -->
      </div>

      <div class="column is-10">
        <div class="box box-info" style="color:black">
          <div class="box-header content">
            <div class="text-center">
              <h1>ADD {{ $store.state.user.locale == 'en_US' ? 'ORGANIZATION' : 'ORGANISATION' }}</h1>
              <p>Before you can start a subscription with EdShed you need to create a billing {{ $store.state.user.locale == 'en_US' ? 'organization' : 'organisation' }}. That can be a school, a business or a person. You will be asked to choose a subscription or a FREE trial in the next step.</p>
            </div>
          </div>
          <div class="box-body">
            <div class="stepwizard">
              <div class="stepwizard-row">
                <div class="stepwizard-step">
                  <button type="button" class="btn button is-success btn-circle" disabled="disabled">
                    1
                  </button>
                  <p>Create Account</p>
                </div>
                <div class="stepwizard-step">
                  <button type="button" class="btn button is-warning btn-circle" disabled="disabled">
                    2
                  </button>
                  <p>Add {{ $store.state.user.locale == 'en_US' ? 'Organization' : 'Organisation' }}</p>
                </div>
                <div class="stepwizard-step">
                  <button type="button" class="btn button btn-circle" disabled="disabled">
                    3
                  </button>
                  <p>Subscribe</p>
                </div>
              </div>
            </div>
            <p>&nbsp;</p>
            <div class="notification is-warning content">
              <h3>Join Your {{ $store.state.user.locale == 'en_US' ? 'Organization' : 'Organisation' }}</h3>
              <p>If your {{ $store.state.user.locale == 'en_US' ? 'organization' : 'organisation' }} already has an subscription and you have been given a joining code, enter it here.</p>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="schoolCode" class="input is-fullwidth" type="text" placeholder="Joining code">
                </div>
                <div class="control">
                  <button type="submit" :class="{'button':true, 'is-link': true, 'is-loading':loadingSchoolCode}" @click="joinSchoolWithCode()">
                    Join {{ $store.state.user.locale == 'en_US' ? 'Organization' : 'Organisation' }}
                  </button>
                </div>
              </div>
            </div>

            <div class="box box-white">
              <div class="content has-text-centered">
                <h2 class="is-marginless">
                  Choose {{ $store.state.user.locale == 'en_US' ? 'Organization' : 'Organisation' }} Type
                </h2>
              </div>
              <div class="tabs is-toggle is-fullwidth">
                <ul>
                  <li :class="{'is-active': orgType == 'school'}">
                    <a @click="setOrg('school')">
                      <span class="icon is-small"><i class="fas fa-school" aria-hidden="true" /></span>
                      <span>School</span>
                    </a>
                  </li>
                  <li :class="{'is-active': orgType == 'company'}">
                    <a @click="setOrg('company')">
                      <span class="icon is-small"><i class="fas fa-building" aria-hidden="true" /></span>
                      <span>Business</span>
                    </a>
                  </li>
                  <li :class="{'is-active': orgType == 'individual'}">
                    <a @click="setOrg('individual')">
                      <span class="icon is-small"><i class="fas fa-user" aria-hidden="true" /></span>
                      <span>Individual</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <p>&nbsp;</p>

            <form v-if="orgType" class="loginForm" @submit.prevent="validateBeforeSubmit">
              <div class="content is-paddingless">
                <h4 class="is-marginless">
                  <span v-if="orgType == 'school'">School </span><span v-else-if="orgType == 'company'">Business </span>Address
                </h4>
              </div>

              <div class="field">
                <label for="school_name" class="label">*<span v-if="orgType == 'school'">School </span><span v-else-if="orgType == 'company'">Business </span> Name</label>
                <div class="control">
                  <input
                    v-model="school_name"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_name')}"
                    name="school_name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                    @keyup="findSchoolSuggestions"
                    @blur="clearSuggestions"
                  >
                </div>
                <p v-show="errors.has('school_name')" class="help is-danger">
                  {{ errors.first('school_name') }}
                </p>
              </div>

              <div v-if="suggestedSchools && orgType == 'school' && !hideSuggest" class="dropdown is-active is-flex">
                <div id="dropdown-menu" class="dropdown-menu is-12" role="menu">
                  <div class="dropdown-content">
                    <a href="#" class="dropdown-item" @click.prevent="hideSuggestDropdown">- Add New School -</a>
                    <a v-for="sch in suggestedSchools" href="#" class="dropdown-item" @click.prevent="didClickSuggestedSchool(sch)">
                      #{{ sch.id }} - {{ sch.school_name }}, {{ sch.school_town }}, {{ sch.school_country }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="field">
                <label for="school_name" class="label">*Address 1</label>
                <div class="control">
                  <input
                    v-model="school_address1"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_address1')}"
                    name="school_address1"
                    placeholder="Address 1"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('school_address1')" class="help is-danger">
                  {{ errors.first('school_address1') }}
                </p>
              </div>

              <div class="field">
                <label for="school_name" class="label">Address 2</label>
                <div class="control">
                  <input
                    v-model="school_address2"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_address2')}"
                    name="school_address2"
                    placeholder="Address 2"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('school_address2')" class="help is-danger">
                  {{ errors.first('school_address2') }}
                </p>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="school_town" class="label">*City</label>
                <label v-else for="school_town" class="label">*Town / City</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="school_town"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_town')}"
                    name="school_town"
                    placeholder="City"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="school_town"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_town')}"
                    name="school_town"
                    placeholder="Town / city"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('school_town')" class="help is-danger">
                  {{ errors.first('school_town') }}
                </p>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="school_county" class="label">*State</label>
                <label v-else for="school_county" class="label">*County / Region</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="school_county"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_county')}"
                    name="school_county"
                    placeholder="State"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="school_county"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_county')}"
                    name="school_county"
                    placeholder="County / Region"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('school_county')" class="help is-danger">
                  {{ errors.first('school_county') }}
                </p>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="school_postcode" class="label">*Zip Code</label>
                <label v-else for="school_postcode" class="label">*Postcode / Zip</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="school_postcode"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_postcode')}"
                    name="school_postcode"
                    placeholder="ZIP Code"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="school_postcode"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('school_postcode')}"
                    name="school_postcode"
                    placeholder="Postcode"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('school_postcode')" class="help is-danger">
                  {{ errors.first('school_postcode') }}
                </p>
              </div>

              <div class="field">
                <label for="school_country" class="label">*Country</label>
                <div class="control">
                  <div class="select is-medium is-fullwidth">
                    <select
                      v-if="countriesData"
                      v-once
                      id="school_country"
                      v-model="school_country_code"
                      name="school_country"
                      expanded
                    >
                      <option v-for="country in countriesData" :key="country.code" :value="country.code">
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <p v-show="errors.has('school_country')" class="help-block">
                  {{ errors.first('school_country') }}
                </p>
              </div>

              <div v-if="orgType == 'school'" class="field">
                <label for="school_type" class="col-sm-2 control-label">*School Type</label>
                <div class="control">
                  <div class="select is-medium is-fullwidth">
                    <select
                      v-if="school_country_code == 'US'"
                      v-model="school_type"
                      v-validate="'required'"
                      class="form-control"
                      name="school_type"
                      placeholder="School Type"
                      type="text"
                    >
                      <option>Elementary</option>
                      <option>Middle</option>
                      <option>High</option>
                      <option>K-12</option>
                      <option>District</option>
                      <option>Special Ed</option>
                      <option>Private Tutor</option>
                      <option>Home Educator</option>
                      <option>Other</option>
                    </select>
                    <select
                      v-else
                      v-model="school_type"
                      v-validate="'required'"
                      class="form-control"
                      name="school_type"
                      placeholder="School Type"
                      type="text"
                    >
                      <option>Primary</option>
                      <option>Middle</option>
                      <option>Secondary</option>
                      <option>All-Through</option>
                      <option>School Group</option>
                      <option>Special Provision / PRU</option>
                      <option>Private Tutor</option>
                      <option>Home Educator</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <span v-show="errors.has('school_type')" class="help-block">{{ errors.first('school_type') }}</span>
              </div>

              <hr style="clear:both">
              <div class="content is-paddingless">
                <h4 class="is-marginless">
                  Billing Address
                </h4>
              </div>

              <div class="field">
                <button class="button is-info" @click.prevent="getBillingDetailsFromSchool">
                  <span>Copy {{ $store.state.user.locale == 'en_US' ? 'Organization' : 'Organisation' }} Name / Address</span> <span class="icon"><i class="fas fa-angle-double-down" /></span>
                </button>
              </div>

              <div class="field">
                <label for="contact_name" class="label">*Billing Contact Name</label>
                <div class="control">
                  <input
                    v-model="contact_name"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('contact_name')}"
                    name="contact_name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('contact_name')" class="help is-danger">
                  {{ errors.first('contact_name') }}
                </p>
              </div>

              <div class="field">
                <label for="contact_email" class="label">*Billing Contact email</label>
                <div class="control">
                  <input
                    v-model="contact_email"
                    v-validate="'required|email'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('contact_email')}"
                    name="contact_email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('contact_email')" class="help is-danger">
                  {{ errors.first('contact_email') }}
                </p>
              </div>

              <div class="field">
                <label for="contact_phone" class="label">Billing Contact phone</label>
                <div class="control">
                  <input
                    v-model="contact_phone"
                    class="input is-medium"
                    name="contact_phone"
                    placeholder="Phone (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="billing_name" class="label">*{{ orgType == 'school' ? 'District' : 'Billing Organization' }}</label>
                <label v-else for="billing_name" class="label">*Billing name</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="billing_name"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_name')}"
                    name="billing_name"
                    placeholder="District / Billing Organization"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="billing_name"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_name')}"
                    name="billing_name"
                    placeholder="Billing name (school / academy trust / company name)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('billing_name')" class="help is-danger">
                  {{ errors.first('billing_name') }}
                </p>
              </div>

              <div class="field">
                <label for="billing_address1" class="label">*Billing Address 1</label>
                <div class="control">
                  <input
                    v-model="billing_address1"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_address1')}"
                    name="billing_address1"
                    placeholder="Address 1"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('billing_address1')" class="help is-danger">
                  {{ errors.first('billing_address1') }}
                </p>
              </div>

              <div class="field">
                <label for="billing_address2" class="label">Billing Address 2</label>
                <div class="control">
                  <input
                    v-model="billing_address2"
                    class="input is-medium"
                    name="billing_address2"
                    placeholder="Address 2 (optional)"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="billing_town" class="label">*Billing City</label>
                <label v-else for="billing_town" class="label">*Billing Town</label>
                <div class="control">
                  <input
                    v-model="billing_town"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_town')}"
                    name="billing_town"
                    placeholder="City"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('billing_town')" class="help is-danger">
                  {{ errors.first('billing_town') }}
                </p>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="billing_county" class="label">*Billing State</label>
                <label v-else for="billing_county" class="label">*Billing County / State</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="billing_county"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_county')}"
                    name="billing_county"
                    placeholder="State"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="billing_county"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_county')}"
                    name="billing_county"
                    placeholder="County / State"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('billing_county')" class="help is-danger">
                  {{ errors.first('billing_county') }}
                </p>
              </div>

              <div class="field">
                <label v-if="school_country_code == 'US'" for="billing_postcode" class="label">*Billing ZIP Code</label>
                <label v-else for="billing_postcode" class="label">*Billing Postcode / ZIP Code</label>
                <div class="control">
                  <input
                    v-if="school_country_code == 'US'"
                    v-model="billing_postcode"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_postcode')}"
                    name="billing_postcode"
                    placeholder="Postcode"
                    type="text"
                    autocomplete="off"
                  >
                  <input
                    v-else
                    v-model="billing_postcode"
                    v-validate="'required'"
                    :class="{'input':true, 'is-medium':true, 'is-danger': errors.has('billing_postcode')}"
                    name="billing_postcode"
                    placeholder="Postcode"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <p v-show="errors.has('billing_postcode')" class="help is-danger">
                  {{ errors.first('billing_postcode') }}
                </p>
              </div>

              <div v-if="countriesData && reverseCharge">
                <label for="vat_number" class="label">VAT No.</label>
                <b-field>
                  <p class="control">
                    <span class="button is-static">{{ selectedCountryCode }}</span>
                  </p>
                  <b-input v-model="vat_number" placeholder="VAT Number (for EU zero rated VAT)" expanded />
                </b-field>
                <p v-show="errors.has('vat_number')" class="help is-danger">
                  {{ errors.first('vat_number') }}
                </p>
              </div>
              <!-- <div class="field" v-if="countriesData && reverseCharge || true">
                    <label for="vat_number" class="col-sm-2 control-label">VAT No.</label>
                      <div class="control">
                        <span class="input-group-addon">{{ selectedCountryCode }}</span>
                        <input class="form-control" name="vat_number" placeholder="VAT Number (for EU zero rated VAT)" type="text" v-model="vat_number" v-validate="'euvat'" autocomplete="off">
                      </div>

                    <span v-show="errors.has('vat_number')" class="help-block">{{ errors.first('vat_number') }}</span>
                  </div> -->

              <div class="message is-warning" style="clear:both">
                <div class="message-header">
                  <p><b>Warning</b></p>
                </div>
                <div class="message-body">
                  If your {{ $store.state.user.locale == 'en_US' ? 'organization' : 'organisation' }} already has an account, including having had a free trial DO NOT add another {{ $store.state.user.locale == 'en_US' ? 'organization' : 'organisation' }}. Instead, speak to your existing account manager for a Joining Code to gain access.
                </div>
              </div>
              <div v-if="response" class="alert alert-error">
                <p>{{ response }}</p>
              </div>
              <div class="has-text-centered">
                <!-- <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('terms')}">
                        <div class="col-sm-offset-2 col-sm-10">
                          <div class="checkbox">
                            <label>
                              <input name="terms" v-model="terms" v-validate="'required'" type="checkbox"> I agree to the <a href="#">terms of use</a>.
                            </label>
                          </div>
                        </div>
                      </div> -->
                <!-- <div class="field">
                          <b-switch v-model="terms"
                          type="is-success">
                          I agree to the <a href="#">terms of use</a>.
                          </b-switch>
                      </div> -->
                <button type="submit" :class="{'button': true, 'is-link': true, 'is-loading':submitting}">
                  Submit
                </button>
              </div>
            </form>
            <p>&nbsp;</p>
            <!-- errors -->
            <div class="text-center col-md-12">
              <span v-if="$store.state.user.all_schools"><router-link to="/schools">Manage Schools</router-link> | </span><router-link to="/logout">
                Sign out
              </router-link> <span v-if="!$store.state.user.all_schools">| <a href="#" @click.prevent="deleteUserAccount">Delete account</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <footer class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { Validator } from 'vee-validate'
import _ from 'lodash'
import { request } from '@/edshed-common/api'

export default {
  name: 'AddSchool',
  data (router) {
    return {
      section: 'Login',
      countriesData: null,
      loading: '',
      response: '',
      contact_name: this.$store.state.user.name,
      contact_email: this.$store.state.user.email,
      contact_phone: '',
      school_name: '',
      school_address1: '',
      school_address2: '',
      school_town: '',
      school_county: '',
      school_postcode: '',
      billing_name: '',
      billing_address1: '',
      billing_address2: '',
      billing_town: '',
      billing_county: '',
      billing_postcode: '',
      school_country_code: 'GB-ENG',
      vat_number: '',
      school_type: 'Primary',
      terms: false,
      submitting: false,
      suggestedSchools: null,
      loadingSchoolCode: false,
      schoolCode: '',
      district: null,
      orgType: 'school',
      hideSuggest: false
    }
  },
  computed: {
    school_country () {
      let cnt = 'England'
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        // console.log(c)
        cnt = c[0].name
      }
      return cnt
    },
    selectedCountryCode () {
      let cd = ''
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        // console.log(c)
        if (c.length) {
          const cda = c[0].code
          const cds = cda.split('-')
          cd = cds[0]
        }
      }
      return cd
    },
    reverseCharge () {
      let rc = false
      if (this.countriesData) {
        const c = _.filter(this.countriesData, { code: this.school_country_code })
        // console.log(c)
        rc = c.length && c[0].reverse_charge === 1
      }
      return rc
    },
    vatNum () {
      if (this.vat_number.trim() !== '') {
        return this.selectedCountryCode + this.vat_number
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$router.push({ path: '/noaccess' })
      // if (this.$store.state.user.school != null) {
      //   console.log('school exists')
      //   this.$router.push({ path: '/subscription', query: this.$route.query })
      // }
      // if (this.$store.state.user.locale === 'en_US') {
      //   this.school_country_code = 'US'
      //   this.school_type = 'Elementary'
      // }
      // if (this.$store.state.user.locale === 'en_AU') {
      //   this.school_country_code = 'AU'
      // }
      // if (this.$store.state.user.locale === 'en_NZ') {
      //   this.school_country_code = 'NZ'
      // }
      // this.loadCountries()
      // if (this.$store.state.user.district_id) {
      //   this.loadDistrict()
      // }
    })
    const dictionary = {
      en_GB: {
        attributes: {
          contact_name: 'Billing Contact Name',
          contact_email: 'Contact Email',
          contact_phone: 'Contact Phone Number',
          school_name: 'Name',
          school_address1: 'Address',
          school_address2: '',
          school_town: 'Town / City',
          school_county: 'County / State',
          school_postcode: 'Postcode / Zipcode',
          billing_name: 'Billing Name',
          billing_address1: 'Billing Address',
          billing_address2: '',
          billing_town: 'Billing Town / City',
          billing_county: 'Billing County / State',
          billing_postcode: 'Billing Postcode / Zipcode',
          school_country: 'Country',
          vat_number: 'VAT Number',
          school_type: 'School Type',
          terms: 'Terms'
        },
        messages: {}
      },
      en_US: {
        attributes: {
          contact_name: 'Billing Contact Name',
          contact_email: 'Contact Email',
          contact_phone: 'Contact Phone Number',
          school_name: 'Name',
          school_address1: 'Address',
          school_address2: '',
          school_town: 'City',
          school_county: 'State',
          school_postcode: 'Zip',
          billing_name: 'Billing Name',
          billing_address1: 'Billing Address',
          billing_address2: '',
          billing_town: 'Billing City',
          billing_county: 'Billing State',
          billing_postcode: 'Zip',
          school_country: 'Country',
          vat_number: 'VAT Number',
          school_type: 'School Type',
          terms: 'Terms'
        },
        messages: {}
      }
    }
    Validator.localize('en_GB', dictionary.en_GB)
    // Validator.extend('euvat', {
    //   getMessage: field => 'The ' + field + ' value is not valid.',
    //   validate: value => utils.isEuroVat(value)
    // })
  },
  methods: {
    hideSuggestDropdown () {
      this.hideSuggest = true
    },
    setOrg (type) {
      this.orgType = type
      if (type === 'individual') {
        if (!this.school_name) {
          this.school_name = this.$store.state.user.name
        }
        this.school_type = 'Individual'
      } else {
        this.school_name = ''
        if (type == 'school') {
          this.school_type = 'Primary'
          if (this.school_country_code === 'US') {
            this.school_type = 'Elementary'
          }
        } else {
          this.school_type = 'Business'
        }
      }
    },
    getBillingDetailsFromSchool () {
      this.billing_name = this.school_name
      this.billing_address1 = this.school_address1
      this.billing_address2 = this.school_address2
      this.billing_town = this.school_town
      this.billing_county = this.school_county
      this.billing_postcode = this.school_postcode
    },
    clearSuggestions () {
      const that = this
      setTimeout(function () {
        that.suggestedSchools = null
      }, 50)
    },
    findSchoolSuggestions () {
      console.log(this.school_name)
      if (!this.school_name || this.school_name === '' || this.school_name.length < 3 || this.orgType != 'school') {
        this.suggestedSchools = null
        return
      }
      request('GET', 'schools?q=' + this.school_name, null)
        .then((response) => {
          const data = response.data
          if (data.schools && data.schools.length > 0) {
            this.suggestedSchools = data.schools
          } else {
            this.suggestedSchools = null
          }
        // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    joinSchoolWithCode () {
      this.loadingSchoolCode = true
      request('POST', 'users/me/joinschool', { code: this.schoolCode })
        .then((response) => {
          const data = response.data
          // save user
          this.loadingSchoolCode = false
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
            console.log('redirecting')
            this.$router.push('/')
          } else {
            this.schoolCode = ''
            alert('Code not recognised. Please check with your school admin to ensure your code is correct.')
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadingSchoolCode = false

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    didClickSuggestedSchool (sch) {
      const p = prompt('To join this school please request a joining code from your school admin, ' + sch.contact_name + ', and enter it here. Do not add another school.')
      if (p) {
        console.log(p)
        request('POST', 'users/me/joinschool', { code: p })
          .then((response) => {
            const data = response.data
            // save user
            if (data.user) {
              this.$store.commit('SET_USER', data.user)

              // console.log('user: ' + JSON.stringify(data.user))

              if (window.localStorage) {
                window.localStorage.setItem('user', JSON.stringify(data.user))
              }
              console.log('redirecting')
              this.$router.push('/')
            } else {
              alert('Code not recognised.')
            }
          })
          .catch((error) => {
            console.log(error)

            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    loadCountries () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      // var that = this
      request('GET', 'countries', null)
        .then((response) => {
          const data = response.data
          this.countriesData = data.countries
          // console.log('readerData: ' + this.readerData)
          if (!this.countriesData || this.countriesData.length === 0) {
            this.response = 'No Countries'
            console.warn('No countries returned from the API! This could mean no seed data has been imported.')
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    loadDistrict () {
      // var that = this
      request('GET', 'users/me/district', null)
        .then((response) => {
          const data = response.data
          if (data.district) {
            this.district = data.district
            this.contact_name = this.district.contact_firstname + ' ' + this.district.contact_lastname
            this.contact_email = this.district.contact_email
            this.billing_name = this.district.name
          }
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    deleteUserAccount () {
      const c = confirm('Are you sure you want to delete your user account? All data will be lost and this cannot be undone.')
      if (c) {
        // do delete
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
        request('DELETE', 'users/me', null)
          .then((response) => {
            const data = response.data

            this.toggleLoading()
            this.$store.commit('TOGGLE_LOADING')
            if (data.success) {
              this.$router.push('/logout')
            }
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    validateBeforeSubmit () {
      if (this.submitting) {
        return
      }
      this.response = ''
      this.submitting = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.checkCreds()
          return
        }
        this.submitting = false
        // alert('Correct them errors!')
      })
    },
    checkCreds (e) {
      // if (!this.terms) {
      //   this.submitting = false
      //   this.response = "Accept terms to continue"
      //   return
      // }
      const { contact_name, contact_email, contact_phone, school_name, school_address1, school_address2, school_town, school_county, school_postcode, billing_name, billing_address1, billing_address2, billing_town, billing_county, billing_postcode, school_country, school_country_code, school_type, terms, vatNum } = this

      console.log(utils.isEmail)

      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')

      /* Making API call to authenticate a user */
      request('POST', 'users/me/school', { contact_name, contact_email, contact_phone, school_name, school_address1, school_address2, school_town, school_county, school_postcode, billing_name, billing_address1, billing_address2, billing_town, billing_county, billing_postcode, school_country, school_country_code, school_type, terms, vatNum })
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */
          this.submitting = false
          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
            console.log('redirecting')
            this.$router.push({ path: '/subscription', query: this.$route.query })
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          this.submitting = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>

<style scoped>
  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }

  .stepwizard-step p {
    margin-top: 10px;
  }

  .stepwizard-row {
      display: table-row;
  }

  .stepwizard {
      display: table;
      width: 100%;
      position: relative;
  }

  .stepwizard-step button[disabled] {
      opacity: 1 !important;
      filter: alpha(opacity=100) !important;
  }

  .stepwizard-row:before {
      top: 14px;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #ccc;
      z-index: 0;

  }

  .stepwizard-step {
      display: table-cell;
      text-align: center;
      position: relative;
  }

  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }

</style>

<style scoped>

.form-group.has-error .input-group-addon {
  border-color: #dd4b39;
}
.dropdown {
  margin-top: -15px;
}
.dropdown-menu {
  padding: 0 !important;
  width:100%;
}
</style>
